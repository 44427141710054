import { Fragment, FunctionalComponent, h } from 'preact';
import {
  BackgroundImageStyled,
  BackgroundShadowStyled,
  ButtonsStyled,
  CallToActionSectionStyled,
  CareAdviceLogoStyled,
  DescriptionStyled,
  HomePageContentStyled,
  HomePageStyled,
  HowItWorksContainerStyled,
  HowItWorksContentDetailStyled,
  HowItWorksContentImageStyled,
  HowItWorksContentStyled,
  HowItWorksContentSubtitleStyled,
  HowItWorksContentTitleStyled,
  HowItWorksDescriptionStyled,
  HowItWorksOverlayStyled,
  HowItWorksSectionBackgroundImageStyled,
  HowItWorksSectionBackgroundShadowStyled,
  HowItWorksSectionStyled,
  JoinLivingRewardsButtonStyled,
  SignInButtonStyled,
  SliderStyled,
  TitleStyled,
  WelcomeLivingRewardsContentStyled,
  WelcomeSliderContainerStyled,
  WelcomLivingRewardsLeftContentStyled,
} from './home.style';
import SwiperCore from 'swiper';
import { getImageSrc, link, route } from '@authentic/loyalty-pwa/util';
import { BaseLayout } from '../../shared/base-layout/base-layout';

import {
  HOW_IT_WORKS_BUTTON_TEXT,
  HOW_IT_WORKS_DESCRIPTION1,
  HOW_IT_WORKS_DESCRIPTION2,
  HOW_IT_WORKS_SUBTITLE,
  HOW_IT_WORKS_TITLE,
  WELCOME_TO_LIVING_REWARDS_SECTION_DESCRIPTION,
  WELCOME_TO_LIVING_REWARDS_SECTION_TITLE,
} from './home.strings';
import { NavSlider } from '@authentic/loyalty-pwa/design-system/components/base/nav-slider/nav-slider';
import { SwiperSlide } from 'swiper/react/swiper-react';
import { useState } from 'preact/hooks';
import { SliderImage } from '@greencross/components/slider-image/slider-image.component';
import { CallToActionModel1 } from '@greencross/components/call-to-action/model1/call-to-action-model1.component';
import {
  Button,
  ButtonVariant,
} from '@greencross/overrides/design-system/components/base/button/button.component';
import { SIGNUP_URL } from '../sign-up/sign-up.strings';
import { SIGN_IN_URL } from '@authentic/loyalty-pwa/Pages/unauthenticated/sign-in/sign-in.strings';
import { useUser } from '@authentic/loyalty-pwa/providers';
import { ABOUT_US_URL } from '../about-us/about-us.strings';
import {
  CarouselImage,
  HomeCarouselImagesModel,
} from './home-carousel-images.model';

type HomeCtaList = {
  image: string;
  title: string;
  link: {
    text: string;
    url: string;
  };
};
interface HomeProps {}
export const HomePage: FunctionalComponent<HomeProps> = (props) => {
  const user = useUser();
  const [homeCtaList, setHomeCtaList] = useState<HomeCtaList[]>([
    {
      image: getImageSrc('/assets/images/home-ctas/icons1.png'),
      title: 'SHOP ONLINE NOW',
      link: {
        text: 'Find Out More >',
        url: 'http://lifepharmacy.co.nz/',
      },
    },
    {
      image: getImageSrc('/assets/images/home-ctas/icons2.png'),
      title: 'PHARMACY FINDER',
      link: {
        text: 'Find a Pharmacy Near you >',
        url: 'https://www.lifepharmacy.co.nz/pharmacy-finder/',
      },
    },
    {
      image: getImageSrc('/assets/images/home-ctas/icons3.png'),
      title: 'PHARMACY SERVICES',
      link: {
        text: 'Find Out More >',
        url: 'https://www.lifepharmacy.co.nz/services/',
      },
    },
    {
      image: getImageSrc('/assets/images/home-ctas/icons4.png'),
      title: 'SHOP OUR CATALOGUE',
      link: {
        text: 'Find Out More  >',
        url: 'https://www.lifepharmacy.co.nz/online-catalogue/',
      },
    }
  ]);

  const [images, setImages] = useState<CarouselImage[]>(
    new HomeCarouselImagesModel().getImages()
  );
  const onSwiperSlideClick = (swiper: SwiperCore) => {
    if (images && images[swiper.activeIndex] && typeof images[swiper.activeIndex].onClick === 'function')
      images[swiper.activeIndex].onClick();
  };
  const onHowItWorksClick = () => {
    route(ABOUT_US_URL);
  };
  const onBackgroundClick = () => {
    route(SIGNUP_URL);
  };

  return (
    <HomePageStyled className='home-page'>
      <BaseLayout>
        
        <WelcomeSliderContainerStyled>

        <SliderStyled>
            <NavSlider
              navigation={true}
              slidesPerView={1}
              spaceBetween={0}
              onTap={onSwiperSlideClick}
              pagination={{
                clickable: true,
              }}
              autoplay={{
                delay: 4000,
                disableOnInteraction: false,
              }}
            >
              {images &&
                images.map((item, index) => (
                  <SwiperSlide>
                    <SliderImage image={item.path}></SliderImage>
                  </SwiperSlide>
                ))}
            </NavSlider>
          </SliderStyled>

          <WelcomeLivingRewardsContentStyled>
            {/* <WelcomLivingRewardsLeftContentStyled>
              <TitleStyled>
                {WELCOME_TO_LIVING_REWARDS_SECTION_TITLE}
              </TitleStyled>
              <DescriptionStyled>
                {WELCOME_TO_LIVING_REWARDS_SECTION_DESCRIPTION}
              </DescriptionStyled>
              {!user.profile?.email && (
                <ButtonsStyled>
                  <JoinLivingRewardsButtonStyled href={link(SIGNUP_URL)}>
                    <Button variant={ButtonVariant.PRIMARY}>
                      JOIN LIVING REWARDS
                    </Button>
                  </JoinLivingRewardsButtonStyled>
                  <SignInButtonStyled href={link(SIGN_IN_URL)}>
                    <Button variant={ButtonVariant.SECONDARY}>SIGN in</Button>
                  </SignInButtonStyled>
                </ButtonsStyled>
              )}
            </WelcomLivingRewardsLeftContentStyled> */}
            {/* <BackgroundShadowStyled
              backgroundImage={`url(${getImageSrc(
                '/assets/images/backgrounds/Welcome_Living_Rewards_Background_optimized_column_shadow.jpeg'
              )})`}
            ></BackgroundShadowStyled> */}
            <BackgroundImageStyled
              backgroundImage={`url(${getImageSrc(
                '/assets/images/backgrounds/GXH_WebsiteRebrandSEPT23.png'
              )})`}
              MobileBackgroundImage={`url(${getImageSrc(
                '/assets/images/backgrounds/GXH_WebsiteRebrandSEPT23.png'
              )})`}
              MobileBackgroundImage480={`url(${getImageSrc(
                '/assets/images/backgrounds/GXH_WebsiteRebrandSEPT23.png'
              )})`}
              onClick={onBackgroundClick}
              style={{ cursor: 'pointer' }} 
            >
              {/* <CareAdviceLogoStyled>
                <img
                  src={getImageSrc('/assets/images/CareAdvice_Logo.png')}
                  alt=''
                />
              </CareAdviceLogoStyled> */}
            </BackgroundImageStyled>
          </WelcomeLivingRewardsContentStyled>

        </WelcomeSliderContainerStyled>

        <HomePageContentStyled className='home-content'>
          <CallToActionSectionStyled>
            {homeCtaList &&
              homeCtaList.map((item, index) => (
                <CallToActionModel1
                  title={item.title}
                  link={
                    <Fragment>
                      <a target='_blank' href={item.link.url}>
                        {item.link.text}
                      </a>
                    </Fragment>
                  }
                  image={getImageSrc(item.image)}
                ></CallToActionModel1>
              ))}
          </CallToActionSectionStyled>
        </HomePageContentStyled>

        <HowItWorksSectionStyled>
          <HowItWorksContainerStyled>
            {/* <HowItWorksContentStyled>
              <HowItWorksContentDetailStyled>
                <HowItWorksContentTitleStyled>
                  {HOW_IT_WORKS_TITLE}
                </HowItWorksContentTitleStyled>
                <HowItWorksContentImageStyled
                  src={getImageSrc('/assets/images/150Equals10_Flags.png')}
                ></HowItWorksContentImageStyled>
                <HowItWorksContentSubtitleStyled>
                  {HOW_IT_WORKS_SUBTITLE}
                </HowItWorksContentSubtitleStyled>
                <HowItWorksDescriptionStyled>
                  {HOW_IT_WORKS_DESCRIPTION1}
                </HowItWorksDescriptionStyled>
                <HowItWorksDescriptionStyled className='strong'>
                  {HOW_IT_WORKS_DESCRIPTION2}
                </HowItWorksDescriptionStyled>
                <Button
                  onClick={onHowItWorksClick}
                  variant={ButtonVariant.PRIMARY}
                >
                  {HOW_IT_WORKS_BUTTON_TEXT}
                </Button>
              </HowItWorksContentDetailStyled>
              <HowItWorksOverlayStyled></HowItWorksOverlayStyled>
            </HowItWorksContentStyled> */}

            <HowItWorksSectionBackgroundImageStyled
              backgroundImage={`url(${getImageSrc(
                '/assets/images/backgrounds/LR_how_it_works.png'
              )})`}
              MobileBackgroundImage={`url(${getImageSrc(
                '/assets/images/backgrounds/LR_how_it_works_mobile.png'
              )})`}
            ></HowItWorksSectionBackgroundImageStyled>
          </HowItWorksContainerStyled>
          <HowItWorksSectionBackgroundShadowStyled
            backgroundImage={`url(${getImageSrc(
              '/assets/images/backgrounds/how-it-works_shadow_column.png'
            )})`}
          ></HowItWorksSectionBackgroundShadowStyled>
        </HowItWorksSectionStyled>
      </BaseLayout>
    </HomePageStyled>
  );
};
