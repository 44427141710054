import { SIGN_IN_URL } from "@authentic/loyalty-pwa/Pages/unauthenticated/sign-in/sign-in.strings";
import { Fragment, h } from "preact";
import { Link } from "preact-router";
import { PRIVACY_POLICY_URL } from "../privacy-policy/privacy-policy.strings";

export const TERMS_AND_CONDITIONS_URL = '/terms-and-conditions';
export const TERMS_AND_CONDITIONS_HEADER = 'Living Rewards Loyalty Programme Terms & Conditions';
export const TERMS_AND_CONDITIONS_BODY = (
	<Fragment>
		<ol>
			<li>The Living Rewards programme is operated by Green Cross Health.</li>
			<li>Living Rewards is exclusive to Unichem and Life Pharmacy.</li>
			<li>Membership is free.</li>
			<li>You must have a valid email address to be eligible to be a member of the Living Rewards programme.</li>
			<li>
				Signing up to the Living Rewards programme, you are automatically opted in to receive marketing messages via email, direct mail and phone, you need to opt in to receive SMS marketing messages from us,
				<ul>
					<li>
						Should you wish to opt out of receiving email, direct mail, phone or SMS messages please
						<ul>
							<li>unsubscribe either within the communication medium received, or</li>
							<li>this can be managed via your profile <Link href={SIGN_IN_URL}>www.livingrewards.co.nz/sign-in</Link> or</li>
							<li>via emailing <Link href="mailto:hello@livingrewards.co.nz">hello@livingrewards.co.nz</Link> or</li>
							<li>contacting us on 0800 762 672</li>
						</ul>
					</li>
				</ul>
			</li>
			<li>By applying for and using your Living Rewards card, you consent to the collection, storage, use and disclosure of your personal information in accordance with our <Link href={PRIVACY_POLICY_URL}>Privacy Policy.</Link></li>
			<li>Each member of Living Rewards is bound to the full terms and conditions of the programme, that may vary from time to time.</li>
			<li>Green Cross Health may cancel, withdraw, or alter the programmes, including these terms and conditions at any time at our discretion. Green Cross Health reserves the right to alter the reward rate at any time.</li>
			<li>Green Cross Health may cancel any membership of the Living Rewards programme at any time in its absolute discretion.</li>
			<li>On cancellation of the Living Rewards programme or membership of the Living Rewards programme all accrued points are forfeited. Green Cross Health will honour current Reward Vouchers providing they have not expired.</li>
			<li>
				The standard rate of points collection is:
				<ul>
					<li>
						Retail items:
						<ul>
							<li>One Living Rewards point for every dollar spent on eligible retail items using Swedish rounding at product level, not on total transaction spend.</li>
							<li>To earn points members must either present their Living Rewards card or provide an email address or mobile number at time of purchase for points to be allocated to their membership profile.</li>
						</ul>
					</li>
					<li>
						Prescription items:
						<ul>
							<li>One Living Rewards point will be allocated per prescription item on the initial prescription only. Refer to point 12.</li>
							<li>To earn points members must either present their Living Rewards card or provide an email address or mobile number at time of purchase for points to be allocated to their membership profile.</li>
						</ul>
					</li>
				</ul>
			</li>
			<li>
				Free Prescriptions:
				<ul>
					<li>One Living Rewards point will be allocated per prescription item on the initial prescription only.</li>
					<li>Living Rewards points are not available on repeat prescriptions.</li>
					<li>To earn points members must either present their Living Rewards card or provide an email address or mobile number at time of purchase for points to be allocated to their membership profile.</li>
				</ul>
			</li>
			<li>Charges relating to partially funded or unfunded medicine prescriptions may apply.</li>
			<li>A customer can join the Living Rewards programme and earn points the same day.</li>
			<li>If we identify that you hold two or more cards, we may merge them into one account at our discretion. You may hold only one card and membership number. Memberships and cards cannot be held jointly.</li>
			<li>Green Cross Health does not send any communication to addresses outside of New Zealand. Living Rewards is a New Zealand-based loyalty programme. All members must also have a valid email address.</li>
			<li>Living Rewards is a consumer rewards programme, available for individuals only, not organisations or groups. Your Living Rewards account is not transferable and can only be used by the person whose name corresponds with the member profile. A member may terminate his or her membership in the Living Rewards programme at any time by giving notice in writing and returning his or her card to Green Cross Health. All accrued points are automatically forfeited on termination.</li>
			<li>Bulk buyers do not qualify to receive Living Rewards points on purchases, including when the purchases are transacted as multiple purchases/transactions.</li>
			<li>Green Cross Health reserves the right to notify law enforcement agencies and to pursue any remedies that may be available in the case of improper use or fraudulent activity to the loyalty programme or any other systems.</li>
			<li>Green Cross Health reserves the right to exclude some promotions/products from accumulating Living Rewards points. We reserve the right to substitute or remove products and services available for points collection and redemption in store and online without notice.</li>
			<li>If you return a product for refund, we will deduct the number of points collected from your Living Rewards account for that product. If a reward voucher was triggered as a result of the product purchase which has now been returned, Green Cross Health reserves the right to cancel the voucher also.</li>
			<li>Your Living Rewards card must be presented, or an email address or mobile number provided at time of purchase to earn points. If you make a purchase online, you must log into your Living Rewards account prior to placing the order. Points cannot be retrospectively allocated once a purchase is complete.</li>
			<li>Any discrepancies in points balance or rewards must be reported to Green Cross Health promptly, otherwise the points balance and rewards issued will be deemed to be correct and binding on the member. Green Cross Health's decision on any such dispute is final and binding.</li>
			<li>Reward vouchers may only be redeemed upon presentation of the Living Rewards card (or appropriate ID) that corresponds with the membership number appearing on the Member profile.</li>	
			<li>Living Rewards Vouchers expire 2 months from the date of issue.</li>
			<li>Living Rewards Points will not be earned on the redemption of Vouchers.</li>
			<li>Living Rewards Vouchers must be used in one visit. No change or credit will be given.</li>
			<li>Living Rewards points can be earned at any participating Unichem or Life Pharmacy and <Link target='_blank' href="https://www.lifepharmacy.co.nz">www.lifepharmacy.co.nz</Link>.</li>
			<li>Living Rewards points will be earned for purchases of products and services in-store and online excluding the following: gift cards, hearing aids and audiology services, calling cards and mobile phone top-ups, beauty services, booking fees, hire equipment fees, NZ Post goods and services, shipping and handling, gift wrapping and any other products or services GXH advises from time to time. Living Rewards points will be earned on the redemption of gift vouchers, not the purchase of gift vouchers. Living Rewards vouchers cannot be redeemed against money owing on accounts or layby transactions.</li>
			<li>When you accumulate 150 Points within a 12-month rolling period a $10 Living Rewards voucher will be available for you to spend when you next shop at a participating Unichem or Life Pharmacy or online at <Link target='_blank' href="https://www.lifepharmacy.co.nz">www.lifepharmacy.co.nz</Link>. Any remaining points not falling within a multiple of 150 will represent your new points balance. Rewards Vouchers are electronic and not transferable.</li>
			<li>All Living Rewards points expire 12 months from the date on which they were earned.</li>
			<li>If your Living Rewards Card is lost, stolen or damaged you must advise us as soon as possible that it is lost, stolen or damaged. You can request a new card upon visiting one of our stores. Green Cross Health does not accept any responsibility for any lost, stolen or damaged card or any loss or theft of points or vouchers resulting from that loss, theft or damage.</li>
			<li>Except for any liability that cannot be excluded by law, Green Cross Health will not be liable for any loss or damage; whether direct, indirect, special or consequential, arising in any way out of the Living Rewards programme, including but not limited to, where arising out of: (a) any technical difficulties, network failures, virus or equipment malfunction (whether or not under our control); (b) any loss, theft, unauthorised access or third party interference with or of points, vouchers, membership information or the Living Rewards card; (c) any points or vouchers that are not properly recorded against a member’s Living Rewards card; and (d) participation in, or termination of, the Living Rewards programme.</li>
			<li>“Eligibility Age” Members must be 13 years or older in order to join the Living Rewards programme. Minors require adult or parental consent.</li>
		</ol>
	</Fragment>
);