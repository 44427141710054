import { getImageSrc } from "@authentic/loyalty-pwa/util";
import { MY_REWARDS_URL } from "@greencross/pages/authenticated/my-rewards/my-rewards.strings";
import { Fragment, h } from "preact";
import { TERMS_AND_CONDITIONS_URL } from "../terms-and-conditions/terms-and-conditions.strings";

export const HOW_IT_WORKS_HEADER = "How it works";
export const HOW_IT_WORKS_BODY = (
	<Fragment>
		<p>You’ll earn 1 point for every $1 spend on retail items and 1 point for each script item purchased. Once you achieve 150 points, you will be rewarded with a $10 voucher.</p>
		<img src={getImageSrc("/assets/images/backgrounds/1170x430px6-Points-banner.jpg")} />
		<p>You have 2 months to use your voucher.</p>
	</Fragment>
);
export const MORE_ABOUT_LIVING_REWARDS = "More about Living Rewards";
export const MORE_ABOUT_URL = "/more-about";
export const MANAGE_ACCOUNT_HEADER = "Manage your account";
export const MANAGE_ACCOUNT_BODY = (
	<Fragment>
		<p>By setting up an online account, you can view your point and voucher balances right here on <a href="/">livingrewards.co.nz</a>. Simply sign in at the top right of screen, then click on your email address to access your account details.</p>
		<h3>Change of address, number or a new email?</h3>
		<p>Simply update your contact details at anytime in the ‘<a href={MY_REWARDS_URL}>My Rewards</a>’ tab. Keeping your account information complete and up to date at all times allows you to make the most of all the benefits Living Rewards has to offer.</p>
		<h3>Going on holiday and want to pause your communications?</h3>
		<p>Unsubscribe and resubscribe as you wish in the ‘<a href={MY_REWARDS_URL}>My Rewards</a>’ tab. Simply scroll down to the section headed ‘Communications Preferences’.</p>
		<p>To get the most out of the programme, we recommend you subscribe to our emails. You will receive notifications as you receive vouchers and a 15 day reminder before your vouchers are due to expire. We also send relevant email communications with special offers and promotions exclusive to our members.</p>
		<p>*Points are calculated at $1 = 1 point for each retail item using Swedish rounding, not on total transaction spend. Paid prescriptions and pharmacy services = 1 point per item. Exclusions apply to purchases of gift cards, hearing aids, audiology services, calling cards and mobile top-ups, beauty services and booking fees, hire equipment fees, shipping, handling and gift wrapping. See full terms and conditions for all information <a href={TERMS_AND_CONDITIONS_URL}>here</a>.</p>
	</Fragment>
)